.superponer {
    z-index: 3
  }
  
  .superponer2 {
    z-index: 4
  }
  
  .superponer3 {
    z-index: 5
  }
  .superponer4 {
    z-index: 6
  }
  .superponer5 {
    z-index: 7
  }
  